// Dependency
import React from "react"
import { graphql } from "gatsby"
import { useStoryblokState } from "gatsby-source-storyblok"
import PageContentSections from "../components/global/page-content-sections/page-content-sections"

const TextContent = ({ data }) => {
  let story = data.storyblokEntry
  story = useStoryblokState(story)
  return (
    <PageContentSections
      largeScreenImage={story.content.largeScreenImage}
      smallScreenImage={story.content.smallScreenImage}
      sections={story.content.sections[0].sections}
      slug={story.full_slug}
      name={story.name}
      date={story.published_at}
    />
  )
}

export default TextContent

export { Head } from "./page"

export const query = graphql`
  query TextContentQuery($full_slug: String!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
      ...StoryEntry
      published_at
    }
  }
`
